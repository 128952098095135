/* General */
.App {
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 300;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.Text {
  color: #fff;
  font-weight: 300;
}
.MarginedDiv {
  margin-left: 14vw;
  margin-right: 14vw;
  margin-top: 210px;
  margin-bottom: 210px;
}

/* Home */

/* Picture Styles */
.PortraitCircle {
  width: 350px;
  min-width: 350px;
  border-radius: 50%;
  border: 1px solid white;
  overflow: hidden; 
  object-fit: contain;
  animation: pulse 5s infinite;
  
}
.PhotoDiv {
  display:'flex';
  flex-direction: row;
  animation: 0.75s ease-out 0s 1 fadeIn;
}

/* Text Styles */
.GreetingText {
  text-align: left;
  font-size: 60px;
  line-height: 0px;
  margin-left: -4px;
}
.TypeIt {
  color: white;
  background-color: unset;
  font-size: 25px;
  line-height: 10px;
}
.ImA {
  font-weight: 500;
}
.RoleTitle {
  font-family: monospace;
  margin-left: 11px;
  margin-top: 1px;
}
.Quote {
  text-align: left;
  font-weight: 100;
  font-size: 20px;
  margin-top: 25px;
  margin-left: -4px;
  margin-right: 50px;
  font-style: italic;
  max-width: 700px;
}

.IconButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.25s ease-in-out;
}

.StackBar {
  width: 45px;
  margin-top: auto;
  margin-bottom: auto;
}

.CompanyBar {
  width: 135px;
  margin-top: auto;
  margin-bottom: auto;
}

.SocialsButton {
  width: max(calc(3vw - 38px), 45px);
  height: max(calc(3vw - 38px), 45px);
  border: 1px solid white;
  border-radius: 50%;
}

.SocialsButtonImg {
  width: max(calc(3vw - 38px), 25px);
}

.Resume {
  max-width: 700px;
  border: 2px solid;
  border-radius: 12px;
}

.CardText {
  font-size: 15px;
}

@media (max-width: 1320px) {
  .PortraitCircle {
    width: 300px;
    min-width: 300px;
  }
}

@media (max-width: 944px) {
  .GreetingText {
    font-size: 50px;
  }
  .TypeIt {
    font-size: 20px;
  }
  .Quote {
    font-size: 15px;
  }
  .Resume {
    max-width: 600px;
  }
}

@media (max-width: 820px){
  .MarginedDiv {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .PhotoDiv {
    flex-direction: column;
    width:'50%';
    margin-left:'auto';
    margin-right:'auto';
    justify-content:'center';
  }
  .PortraitCircle {
    margin-top: 50px;
  }
  .MarginedDiv {
    margin-left: 4%;
    margin-right: 4%;
  }
  .Quote {
    margin-right: 0px;
  }
  .CompanyBar {
    width: 90px;
  }
  .Resume {
    max-width: 500px;
  }
}

@media (max-width: 530px) {
  .PortraitCircle {
    width: 250px;
    min-width: 250px;
  }
  .GreetingText {
    font-size: 50px;
    margin-left: -3px;
  }
  .TypeIt {
    font-size: 20px;
  }
  .Quote {
    font-size: 18px;
  }
  .StackBar {
    width: 30px;
  }
  .CompanyBar {
    width: 60px;
  }
  .Resume {
    max-width: 300px;
  }
  .CardText {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .CardText {
    font-size: 12px;
  }
}

@media (max-width: 331px) {
  .CardText {
    font-size: 11px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.GradientBackground {
  margin: 0;
	background: linear-gradient(45deg, #80497e, #61738a, #442e5e, #151039);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

.SlideText {
  animation: 0.75s ease-out 0s 1 slideIn;
}

.FadeInText {
  animation: 2s ease-out 0.5s 1 fadeIn;
}

@keyframes gradient {
	0% {
		background-position: 0% 75%;
	}
	50% {
		background-position: 100% 75%;
	}
	100% {
		background-position: 0% 75%;
	}
}

@keyframes slideIn {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}
