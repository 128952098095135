body {
  margin: 0;
  	background: linear-gradient(35deg, #80497e, #61738a, #442e5e, #151039);
	background-size: 2000% 2000%;
	animation: gradient 20s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 75%;
	}
	50% {
		background-position: 100% 75%;
	}
	100% {
		background-position: 0% 75%;
	}
}

code {
  font-family: 'Space Mono', 'Courier New',
    monospace;
}

.slick-track {
    margin-left: auto;
	margin-right: auto;
}
