.Sticky{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 50;
}

nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1F6BFF;
    box-shadow: 0 5px 15px rgba(0,0,0, 0.06);
    height: 75px;
}

button {
    text-decoration: none;
    background: none;
    border: none;
    font-size: 0.9rem;
    font-family: 'Space Grotesk';
    font-weight: 300;
    color: #FFFFFF;
    text-align: left;
    cursor: pointer;
    transition: color ease 0.25s;
}

button:hover{
    color:#1F6BFF;
}

#navbar li button:hover::after{
    content: "";
    width: 70%;
    height: 2px;
    background-color:#1F6BFF;
    position: absolute;
    bottom:-4px;
    left:20px;
    animation: fadeIn2 ease 0.25s;
}

.MarginedLeft{
    margin-left: 10vw;
}
.MarginedRight{
    margin-right: 10vw;
}

#navbar{
    display:flex;
    align-items: center;
    justify-content: center;
}

#navbar li{
    list-style: none;
    padding: 0 20px;
    position: relative;
}

@keyframes fadeIn2 {
    0% {
      opacity: 0;
      transform: translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
}

#mobile {
    display:none;
}

#mobile i{
    color: #FFFFFF;
}

@media (max-width: 751px){

    .MarginedLeft{
        margin-left: 4%;
    }
    .MarginedRight{
        margin-right: 4%;
    }
}

@media (max-width: 900px){

    #navbar{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top:59px;
        right:-300px;
        width: 180px;
        height: 100vh;
        box-shadow: 0 40px 60px rgba(0,0,0, 0.1);
        padding: 26px 0 0 0;
        z-index: 50;
        transition: 0.3s ease-in-out;
    }

    #navbar.active{
        right: 0px;
    }

    #mobile {
        display: block;
    }

    #mobile i{
        font-size: 24px;
        cursor: pointer;
    }

    #navbar li{
        margin-bottom: 12.5px;
    }
}